import React, { useEffect, useState, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import "./../../src/Hader.css";
import { FaSearch, FaShoppingCart, FaUser } from "react-icons/fa";

// import { Link } from "react-router-dom";
import Logo from "./../assets/img/logo.png";
import Logo2 from "../assets/img/nobgb.jpg";
import SlideSection from "./SlideSection";
import Cart from "./Cart";
import { FaBagShopping } from "react-icons/fa6";

import { listActiveCategories } from "../functions/Category/category";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MyContext } from "../MyContext";
import { getUserCartByUserId } from "../functions/Auth/UserCart";
import { getProductVariantsInfoInCart } from "../functions/ProductDetails/ProductDetails";
// import { FaShoppingCart } from "react-icons/fa";
import { listActiveMenu } from "../functions/Menu/MenuForm";
import { listCompanynDetails } from "../functions/CompanyDetails/CompanyDetails";
import { BorderBottom } from "@mui/icons-material";

const Hader = ({ Color }) => {
  const [backgroundColor, setBackgroundColor] = useState("none");
  const navigate = useNavigate();

  const {
    isCanvasOpen,
    setIsCanvasOpen,
    setcartDetails,
    setSubTotal,
    setCartCount,
    cartCount,
    setProdVariantsData,
  } = useContext(MyContext);

  useEffect(() => {
    const handleScroll = () => {
      // Calculate the scroll percentage
      const scrollPercentage =
        (window.scrollY / (document.body.scrollHeight - window.innerHeight)) *
        100;

      // Change the background color based on the scroll percentage
      // You can adjust the conditions and colors according to your preference
      if (scrollPercentage < 2) {
        setBackgroundColor("none");
      } else {
        setBackgroundColor("white");
      }
    };

    // Add a scroll event listener to the window
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [categoryData, setCategoryData] = useState([]);

  const [menuData, setMenuData] = useState([]);

  const [companyData, setCompanyData] = useState([]);

  const userId = localStorage.getItem("NicksCoUser");

  useEffect(() => {
    loadCategoryData();
    loadCartData();
    loadMenuData();
    loadCompanyDetails();
  }, []);

  const loadCategoryData = () => {
    listActiveCategories().then((res) => {
      console.log("res CATEG", res.data);
      const invertedData = res.data.reverse(); // Reverse the order of the array
      setCategoryData(invertedData);
    });
  };

  const loadCompanyDetails = () => {
    listCompanynDetails().then((res) => {
      console.log("res CATEG", res.data);
      setCompanyData(res.data);
    });
  };

  const loadMenuData = () => {
    listActiveMenu().then((res) => {
      console.log("res CATEG", res.data);
      const invertedData = res.data.reverse(); // Reverse the order of the array
      setMenuData(invertedData);
      res.data.map((re) => {
        if (re._id === "6643314299a193bb437a2ae7") {
        }
      });
    });
  };

  const loadCartData = () => {
    if (userId) {
      getUserCartByUserId(userId).then((res) => {
        console.log("cart data", res.data);
        const resp = res.data.data;
        setCartCount(resp.length);
      });
    } else {
      const guestCartData = localStorage.getItem("guestCartData");
      if (guestCartData) {
        const cartArray = JSON.parse(guestCartData);
        setCartCount(cartArray.length);
      }
    }
  };

  const toggleCanvas = async () => {
    try {
      console.log("us", userId);
      if (userId) {
        const res = await getUserCartByUserId(userId);
        console.log("cart data", res.data);
        const resp = res.data.data;
        const invertedData = resp.reverse(); // Reverse the order of the array
        setcartDetails(invertedData);
        setCartCount(resp.length);
        setSubTotal(res.data.subTotal);

        // Create an array to store promises
        const promises = resp.map((item) => {
          console.log("variantid", item.productVariantsId);
          if (item.productVariantsId !== null)
            return getProductVariantsInfoInCart(item.productVariantsId).then(
              (res1) => {
                console.log("product variants info", res1.data[0]);
                return res1.data[0]; // Return the object directly
              }
            );
        });

        const variantDataArray = await Promise.all(promises.filter(Boolean));

        setProdVariantsData(variantDataArray);
        console.log("is", isCanvasOpen);
        setIsCanvasOpen(!isCanvasOpen);
      } else {
        const guestCartData = localStorage.getItem("guestCartData");
        if (guestCartData) {
          const cartArray = JSON.parse(guestCartData);
          setCartCount(cartArray.length);

          const invertedData = cartArray.reverse(); // Reverse the order of the array
          setcartDetails(invertedData);

          let subAmt = 0;

          const mappedCart = cartArray.map((item) => {
            const totalAmount = item.amount * item.quantity;
            subAmt = subAmt + totalAmount;
            // return { ...item, totalAmount: totalAmount };
          });
          setSubTotal(subAmt);

          const promises = cartArray.map((item) => {
            console.log("variantid", item.productVariantsId);
            if (item.productVariantsId !== null)
              return getProductVariantsInfoInCart(item.productVariantsId).then(
                (res1) => {
                  console.log("product variants info", res1.data[0]);
                  return res1.data[0]; // Return the object directly
                }
              );
          });

          const variantDataArray = await Promise.all(promises.filter(Boolean));
          console.log("variantDataArray", variantDataArray);

          setProdVariantsData(variantDataArray);
          setIsCanvasOpen(!isCanvasOpen);
        }
      }
    } catch (error) {
      console.error("Error loading user cart:", error);
    }
  };

  const location = useLocation();

  const currentRoute = location.pathname;

  const handleLogout = () => {
    localStorage.removeItem("NicksCoUser");
    window.location.replace("/");
  };

  return (
    <React.Fragment>
      <div
        className="mainDivHader"
        style={{
          fontFamily: "Arial, Helvetica, sans-serif",
          backgroundColor: `${backgroundColor === "none" ? Color : "#000"}`,
          transition: "background-color 0.5s ease",
        }}
      >
        <Row className="haderRow">
          <Col lg={3}>
            <div className="logoDiv">
              {companyData.map((cd, index) => {
                return (
                  <Link to="/" key={index} >
                    <img
                      style={ { borderBottom: "1px solid #fff", paddingBottom: "10px"} }
                      src={Logo2}
                      alt="logo"
                      width={300}
                      height={150}
                    />
                  </Link>
                );
              })}
              {/* <Link to="/">
                <img className="haderLogo" src={Logo} alt="logo" />
              </Link> */}
            </div>
          </Col>
          <Col lg={9}>
            <div className="linksDiv">
              {menuData.map((cd, index) => {
                const isEven = index % 2 === 0;

                const backgroundColorClass = isEven ? "" : "dropDown2";
                if (cd._id === "6643314299a193bb437a2ae7") {
                  return (
                    <>
                      <div
                        key={index}
                        className="dropdown-container"
                        style={{
                          backgroundColor: "black",
                          // backgroundColor: isEven ? "#fff" : "#f1c232", // Fix the syntax here

                          // backgroundColor: {isEven ? "#fff" : "#000"},
                          color: "white",
                          padding: "15px 28px",
                          textAlign: "center",
                          fontWeight: "600 !important",
                        }}
                      >
                        <Link
                          key={index}
                          style={{
                            // color: backgroundColor === "none" ? "#000" : "#000",
                            color: "white",
                            backgroundColor: "black"
                          }}
                          className={`haderLink homeLinks`}
                          to={cd.MenuURL}
                        // to={`${cd.MenuURL}${cd._id ? `/${cd._id}` : ""}`} // Include cd._id conditionally
                        >
                          {cd.MenuName.toUpperCase()}
                        </Link>

                        <ul
                          className="submenu dropdown-menu"
                          aria-labelledby="experience_submenu"
                        >
                          {categoryData
                            .filter(
                              (categ) =>
                                categ.IsActive &&
                                categ.IsSubMenu &&
                                categ.MenuType === cd._id
                            )
                            .map((categ) => {
                              return (
                                <React.Fragment key={categ._id}>
                                  <li>
                                    <a
                                      className="hoverLink"
                                      href={categ.subMenuURL}
                                    >
                                      {categ.categoryName.toUpperCase()}
                                    </a>
                                  </li>
                                </React.Fragment>
                              );
                            })}
                        </ul>
                      </div>

                    </>
                  );
                } else {
                  return (
                    <Link
                      key={index}
                      style={cd.MenuName.toUpperCase() === "VIEW MENU" ? {// color: backgroundColor === "none" ? "#000" : "#000",
                        color: "white",
                        backgroundColor: "black"
                      } : {
                        // color: backgroundColor === "none" ? "#000" : "#000",
                        color: "white",
                        backgroundColor: "black"
                      }}
                      className={`haderLink homeLinks`}
                      to={cd.MenuURL}
                      // to={`${cd.MenuURL}${cd._id ? `/${cd._id}` : ""}`} // Include cd._id conditionally
                      onClick={(event) => cd.MenuName.toUpperCase() === "VIEW MENU" ? window.location.href = "/location" : null}
                    >
                      {cd.MenuName.toUpperCase() === "VIEW MENU" ? "VIEW MENU" : cd.MenuName.toUpperCase()}
                    </Link>
                  );
                }
              })}

              {/* {menuData.map((cd) => {
                return (
                  <Link
                    style={{
                      color: `${backgroundColor === "none" ? "#000" : "#000"}`,
                      
                    }}
                    className="haderLink homeLinks"
                    to={cd.MenuLink}
                  >
                    {cd.MenuName.toUpperCase()}
                  </Link>
                
              })} */}
              {/* <Link
                style={{
                  color: `${backgroundColor === "none" ? "#000" : "#000"}`,
                  
                }}
                className="haderLink homeLinks"
                to="/"
              >
                HOME
              </Link>

              <div className="dropdown-container dropDown2">
                <Link
                  style={{
                    color: `${backgroundColor === "none" ? "#000" : "#000"}`,
                    
                  }}
                  className="haderLink"
                  to="#"
                >
                  LOCATIONS
                </Link>
              
              </div>

              <div className="dropdown-container homeLinks">
                <Link
                  style={{
                    color: `${backgroundColor === "none" ? "#000" : "#000"}`,
                    
                  }}
                  className="haderLink"
                  to="#"
                >
                  ORDER
                </Link>
                <ul
                  className="submenu dropdown-menu"
                  aria-labelledby="experience_submenu"
                >
                  <li>
                    <a className="hoverLink" href="/findstore">
                      PICK LOCATION
                    </a>
                  </li>

                  <li>
                    <a className="hoverLink" href="#">
                      MENU
                    </a>
                  </li>
                </ul>
              </div>

              <div className="dropdown-container dropDown2">
                <Link
                  style={{
                    color: `${backgroundColor === "none" ? "#000" : "#000"}`,
                    
                  }}
                  className="haderLink"
                  to="#"
                >
                  MENU
                </Link>
                <ul
                  className="submenu dropdown-menu"
                  aria-labelledby="experience_submenu"
                >
                  {categoryData.map((cd) =>
                    cd.IsActive && cd.MenuType === "CategoryMenu" ? (
                      <li>
                        <a
                          className="hoverLink"
                          href={`/producListing/${cd._id}`}
                        >
                          {cd.categoryName.toUpperCase()}
                        </a>
                      </li>
                    ) : null
                  )}
                </ul>
              </div>

              <div className="dropdown-container homeLinks">
                <Link
                  style={{
                    color: `${backgroundColor === "none" ? "#000" : "#000"}`,
                    
                  }}
                  className="haderLink"
                  to="#"
                >
                  MERCHANDISE
                </Link>
                <ul
                  className="submenu dropdown-menu"
                  aria-labelledby="experience_submenu"
                >
                  {categoryData.map((cd) =>
                    cd.IsActive && cd.MenuType === "Merchandise" ? (
                      <li>
                        <a
                          className="hoverLink"
                          href={`/producListing/${cd._id}`}
                        >
                          {cd.categoryName.toUpperCase()}
                        </a>
                      </li>
                    ) : null
                  )}
                </ul>
              </div>

              <div className="dropdown-container dropDown2">
                <Link
                  style={{
                    color: `${backgroundColor === "none" ? "#000" : "#000"}`,
                    
                  }}
                  className="haderLink"
                  to="#"
                >
                  BEANS AND LEAVES
                </Link>
                <ul
                  className="submenu dropdown-menu"
                  aria-labelledby="experience_submenu"
                >
                  {categoryData.map((cd) =>
                    cd.IsActive && cd.MenuType === "beansandLeaves" ? (
                      <li>
                        <a
                          className="hoverLink"
                          href={`/producListing/${cd._id}`}
                        >
                          {cd.categoryName.toUpperCase()}
                        </a>
                      </li>
                    ) : null
                  )}
                </ul>
              </div> */}

              {/* <div className="dropdown-container homeLinks">
                <Link
                  style={{
                    color: `${backgroundColor === "none" ? "#000" : "#000"}`,
                    
                  }}
                  className="haderLink"
                  to="#"
                >
                  MORE
                </Link>
                <ul
                  className="submenu dropdown-menu"
                  aria-labelledby="experience_submenu"
                >
                  <li>
                    <a className="hoverLink" href="/aboutUS">
                      ABOUT US
                    </a>
                  </li>

                  <li>
                    <a className="hoverLink" href="/loyalty">
                      LOYALTY
                    </a>
                  </li>
                  <li>
                    <a className="hoverLink" href="/referFriend">
                      REFER FRIENDS
                    </a>
                  </li>
                  <li>
                    <a className="hoverLink" href="/blog">
                      BLOG
                    </a>
                  </li>
                  <li>
                    <a className="hoverLink" href="/shipping">
                      SHIPPING
                    </a>
                  </li>
                  <li>
                    <a className="hoverLink" href="/store-policy">
                      STORE POLICY
                    </a>
                  </li>
                  <li>
                    <a className="hoverLink" href="/faq">
                      FAQ
                    </a>
                  </li>

                  <li>
                    <a className="hoverLink" href="/contactUs">
                      CONTACT US
                    </a>
                  </li>
                  <li>
                    <a className="hoverLink" href="/franchise">
                      FRANCHISE
                    </a>
                  </li>
                </ul>
              </div> */}

              <div
              // style={{
              //   color: `${backgroundColor === "none" ? "#fff" : "#fff"}`,
              // }}
              >
                {/* <div
                  type="button"
                  className="cart_btn1"
                  id="cart"
                  onClick={toggleCanvas}
                  style={{ backgroundColor: "black" }}
                > */}
                <div style={{ padding: "15px" }}>
                  <FaShoppingCart
                    className="fal fa-shopping-bag"
                    // onClick={toggleCanvas}
                    onClick={() => navigate("/shopping-cart")}
                    style={{
                      color: "white",
                      fontSize: "20px",
                    }}
                  />
                  <small className="cart_counter">{cartCount}</small>
                </div>
              </div>

              <div
                style={{
                  color: `${backgroundColor === "none" ? "#fff" : "#fff"}`,
                }}
                className="dropdown-container userBtn"
              >
                <FaUser className="searchIcon" />
                {!localStorage.getItem("NicksCoUser") && (
                  <Link
                    style={{
                      color: `${backgroundColor === "none" ? "#fff" : "#fff"}`,
                    }}
                    className="hoverLink"
                    to="/login"
                  >
                    Login
                  </Link>
                )}

                {localStorage.getItem("NicksCoUser") && (
                  <ul
                    className="submenu dropdown-menu"
                    aria-labelledby="experience_submenu"
                  >
                    <li>
                      <a className="hoverLink" href="/my-address">
                        My Addresses
                      </a>
                    </li>
                    <li>
                      <a className="hoverLink" href="/my-wallet">
                        My Wallet
                      </a>
                    </li>
                    <li>
                      <a className="hoverLink" href="/my-subscription">
                        My Subscriptions
                      </a>
                    </li>
                    <li>
                      <a className="hoverLink" href="/my-orders">
                        My Orders
                      </a>
                    </li>
                    <li>
                      { // <a className="hoverLink" href="/my-rewards">
                        //  My Rewards
                        //  </a>
                      }
                    </li>
                    <li>
                      <a className="hoverLink" href="/my-account">
                        My Account
                      </a>
                    </li>

                    <li>
                      <a className="hoverLink" onClick={handleLogout} href="#">
                        Logout
                      </a>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div hidden={window.location.pathname !== "/"} style={{
              marginTop: "10px",
              color: "#333", 
              display: "flex", 
              alignItems: "center", 
              justifyContent: "center", 
              borderRadius: "10px", 

             
              textAlign: "center", // Center text
              position: "relative", // Position relative to appear on top
              width: "100%",

              height: "auto", // Auto height to fit content
              '@media (max-width: 768px)': {
                fontSize: '16px', // Reduced font size for mobile
                marginTop: '10px' // Reduced margin for mobile
              }
            }}>
              <img
                src={Logo}
                alt="logo"
                style={{ width: "100px", height: "90px" }}
              />
            </div>
          </Col>
          <Col xs={9}>
            <div hidden={window.location.pathname !== "/"} style={{
              marginTop: "10px",
              backgroundColor: "#ffde00", // Bright yellow background
              color: "#333", // Dark text color for contrast
              display: "flex", // Use flex to center content
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally

              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
              fontSize: "19px", // Fixed font size for desktop
              fontWeight: "bold", // Bold text for emphasis
              padding: "1rem", // Padding for better spacing
              textAlign: "center", // Center text
              position: "relative", // Position relative to appear on top
              width: "100%",

              height: "auto", // Auto height to fit content
              '@media (max-width: 768px)': {
                fontSize: '16px', // Reduced font size for mobile
                marginTop: '10px' // Reduced margin for mobile
              }
            }}>
              To order coffee beans and other products, click on the Retail tab. To subscribe for your favorite coffee beans, click on the Subscription tab.
            </div>
          </Col>
        </Row>
      </div>

    </React.Fragment>
  );
};

export default Hader;
